<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <header-search />
    </div>
    <div class="top-row">
      <div class="top-item-box">
        <div class="top-item">
          <img
            src="~@/assets/image/pc/block_nav1.png"
            alt=""
            class="item-img"
          />
          <div class="item-right">
            <div class="item-text">{{ $t("當前區塊") }}</div>
            <div class="item-num">{{ blockNumber }}</div>
          </div>
        </div>
        <div class="top-item ">
          <img
            src="~@/assets/image/pc/block_nav4.png"
            alt=""
            class="item-img"
          />
          <div class="item-right">
            <div class="item-text">{{ $t("手續費") }}</div>
            <div class="item-num">{{ gasPrice }}Gwei</div>
          </div>
        </div>
      </div>
      <div class="top-item-box">
        <div class="top-item">
          <img
            src="~@/assets/image/pc/block_nav2.png"
            alt=""
            class="item-img"
          />
          <div class="item-right">
            <div class="item-text">{{ $t("哈希率") }}</div>
            <div class="item-num">{{ hashrate }}</div>
          </div>
        </div>
        <div class="top-item">
          <img
            src="~@/assets/image/pc/block_nav5.png"
            alt=""
            class="item-img"
          />
          <div class="item-right">
            <div class="item-text">{{ $t("難度值") }}</div>
            <div class="item-num">{{ difficulty }}</div>
          </div>
        </div>
      </div>
      <div class="top-item-box">
        <div class="top-item">
          <img
            src="~@/assets/image/pc/block_nav3.png"
            alt=""
            class="item-img"
          />
          <div class="item-right">
            <div class="item-text flex-middle">
              {{ $t("token數量(個)")
              }}<img src="~@/assets/image/pc/right-more.png" />
            </div>
            <div class="item-num">{{ tokenTotal }}</div>
          </div>
        </div>
        <div class="top-item">
          <img
            src="~@/assets/image/pc/block_nav6.png"
            alt=""
            class="item-img"
          />
          <div class="item-right">
            <div class="item-text">{{ $t("BACK代幣分配細則") }}</div>
            <div class="item-num primary-color curson item-more" @click="$router.push({name: 'PlaceMentDistribution'})">
              {{ $t("點選檢視") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="$isInMobile" class="flex-middle itrm">
      <div
        class="itrm-title "
        :class="{ active: tab === '1' }"
        @click="changeTab('1')"
      >
        {{ $t("區塊高度") }}
      </div>
      <div
        class="itrm-title"
        :class="{ active: tab === '2' }"
        @click="changeTab('2')"
      >
        {{ $t("交易記錄") }}
      </div>
    </div>
    <div class="bottom-table flex">
      <div
        class="table-box flex-item mr table-box-odd"
        v-show="!$isInMobile || ($isInMobile && tab === '1')"
      >
        <div class="table-title">{{ $t("區塊高度") }}</div>
        <el-table :data="blockList" stripe style="width: 100%">
          <el-table-column :label="$t('區塊高度')" width="120px">
            <template #default="scope">
              <router-link
                :to="{
                  name: 'BlockDetial',
                  params: { blockNumber: scope.row.number },
                }"
                >{{ scope.row.number }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('區塊哈希值')" width="300px">
            <template #default="scope">
              <span class="text-ellipsis">{{ scope.row.hash }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('交易次數')"
            prop="transactionNum"
            width="120px"
          >
          </el-table-column>
          <el-table-column :label="$t('區塊產生者')" width="300px">
            <template #default="scope">
              <router-link
                class="text-ellipsis"
                :to="{
                  name: 'AddressDetail',
                  params: { address: scope.row.miner },
                }"
                >{{ scope.row.miner }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('產生時間')" width="180px">
            <template #default="scope">
              <span class="text-ellipsis">{{
                dateFormat(
                  "yyyy-MM-dd hh:mm:ss",
                  new Date(scope.row.timestamp * 1000)
                )
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="table-bottom-more flex-center"
          v-if="blockList && blockList.length == 10"
          @click="$router.push({ name: 'BlockList' })"
        >
          {{ $t("查看更多") }}<i class="el-icon-arrow-right el-icon--right"></i>
        </div>
      </div>
      <div
        class="table-box flex-item mr table-box-odd"
        v-show="!$isInMobile || ($isInMobile && tab === '2')"
      >
        <div class="table-title">{{ $t("交易記錄") }}</div>
        <el-table :data="transactionList" style="width: 100%">
          <el-table-column label="Hash" width="300px">
            <template #default="scope">
              <router-link
                class="text-ellipsis"
                :to="{
                  name: 'TransactionDetial',
                  params: { hash: scope.row.hash },
                }"
                >{{ scope.row.hash }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column width="120px" :label="$t('區塊高度')">
            <template #default="scope">
              <router-link
                :to="{
                  name: 'BlockDetial',
                  params: { blockNumber: scope.row.blockNumber },
                }"
                >{{ scope.row.blockNumber }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('轉出地址')" width="300px">
            <template #default="scope">
              <router-link
                class="text-ellipsis"
                :to="{
                  name: 'AddressDetail',
                  params: { address: scope.row.fromAddress },
                }"
                >{{ scope.row.fromAddress }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('轉入地址')" width="300px">
            <template #default="scope">
              <router-link
                v-if="scope.row.toAddress"
                class="text-ellipsis"
                :to="{
                  name: 'AddressDetail',
                  params: { address: scope.row.toAddress },
                }"
                >{{ scope.row.toAddress }}</router-link
              >
              <span class="text-ellipsis" v-else>{{ $t("建立合約") }}</span>
            </template>
          </el-table-column>
          <el-table-column width="80px" :label="$t('數量')">
            <template #default="scope">
              <span class="text-ellipsis"
                >{{ web3.utils.fromWei(scope.row.ethValue, "ether") }} ds</span
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('時間')">
            <template #default="scope">
              <span class="text-ellipsis">{{
                dateFormat(
                  "yyyy-MM-dd hh:mm:ss",
                  new Date(scope.row.timestamp * 1000)
                )
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div
          class="table-bottom-more flex-center"
          v-if="transactionList && transactionList.length == 10"
          @click="$router.push({ name: 'Transaction' })"
        >
          {{ $t("查看更多") }}<i class="el-icon-arrow-right el-icon--right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import HeaderSearch from "@/components/HeaderSearch";
import Web3 from "web3";
import {
  getGasPrice,
  getHashrate,
  getBlockNumber,
  getBlockInfo,
  getBlockList,
  getTokenAllList,
  getTransactionAllList,
} from "@/api";
export default {
  components: {
    HeaderSearch,
  },
  setup() {
    const router = useRouter();
    const data = reactive({
      web3: Web3,
      wids: 200,
      blockNumber: 0,
      hashrate: 0,
      gasPrice: 0,
      difficulty: 0,
      tokenTotal: 0,
      blockList: [],
      tab: "1",
      changeTab: (tab) => {
        if (tab === data.tab) {
          return false;
        }
        data.tab = tab;
      },
      transactionList: [],
      toBlockDetail: (row, column, event) => {
        router.push({
          name: "BlockDetial",
          params: { blockNumber: row.number },
        });
      },
      toTransactionDetail: (row, column, event) => {
        router.push({ name: "TransactionDetial", params: { hash: row.hash } });
      },
    });
    onMounted(() => {
      getGasPrice().then((res) => {
        data.gasPrice = res.data;
      });
      getHashrate().then((res) => {
        data.hashrate = res.data;
      });
      getBlockNumber()
        .then((res) => {
          data.blockNumber = res.data;
          return getBlockInfo({ blockNumber: res.data });
        })
        .then((block) => {
          data.difficulty = block.data.difficulty;
        });
      getTokenAllList({ page: 1, limit: 60 }).then((res) => {
        data.tokenTotal = res.data.total;
      });
      getBlockList().then((res) => {
        data.blockList = res.data;
      });
      getTransactionAllList({ page: 1, limit: 10 }).then((res) => {
        data.transactionList = res.data.list;
      });
    });
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
};
</script>
<style lang="scss" scoped>
.top-row {
  margin: -90px auto 0;
  max-width: 1400px;
  background: #fff;
  border-radius: 20px;
  height: 180px;
  padding: 10px 0;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  .top-item-box {
    flex: 1;
    padding: 0 30px;
    .top-item {
      display: flex;
      align-items: center;
      height: 84px;
      .item-img {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 18px;
      }
      .item-right {
        .item-text {
          color: #999;
          font-size: 14px;
          margin-bottom: 4px;
          img {
            display: block;
            width: 7px;
            height: auto;
            margin-left: 10px;
          }
        }
        .item-num {
          color: #111;
          font-size: 18px;
          font-weight: bold;
        }
        .item-more {
          font-size: 14px;
          color: #0956d0;
          cursor: pointer;
        }
      }
    }
  }
  .top-text {
    margin-top: 26px;
    color: #222222;
    font-size: 16px;
  }
  .num-text {
    margin-top: 30px;
    color: #0956d0;
    font-size: 26px;
    font-weight: bold;
  }
}
.address-list {
  max-width: 1400px;
  margin: 30px auto 80px;
}
.bottom-table {
  margin: 30px auto;
  max-width: 1400px;
  .table-box {
    margin: 0 auto;
    .table-odd-top {
      height: 80px;
      border-radius: 20px 20px 0 0;
      &.bg-primary {
        background: #0956d0;
        padding: 0 24px;
      }
      &.bg-orange {
        background: #ffb400;
        padding: 0 24px;
      }
      .odd-top-left {
        color: #fff;
        font-size: 16px;
        .odd-top-num {
          font-size: 20px;
        }
      }
      .odd-top-btn {
        color: #fff;
        font-size: 16px;
        border: 1px solid #fff;
        line-height: 46px;
        border-radius: 23px;
        width: 120px;
        text-align: center;
      }
    }
  }
  .table-box:nth-child(1) {
    margin-right: 30px;
  }
  .table-bottom-more {
    cursor: pointer;
    line-height: 60px;
    color: #0956d0;
    font-size: 16px;
  }
}
@media screen and (max-width: 480px) {
  .topbg-wrapper .top-bg {
    height: 155px;
    display: block;
    background: url("~@/assets/image/wap/page-tops.png") no-repeat center / 100%
      100%;
    .top-search-box {
      margin: 0 15px;
      .search-title {
        margin-top: 38px;
        font-size: 16px;
      }
      .search-input-box {
        width: 100%;
        height: 38px;
        margin-top: 10px;
        .search-img {
          width: 16px;
          cursor: none;
        }
      }
    }
  }
  .top-row {
    margin: -25px 15px 12px !important;
    border-radius: 10px;
    background: #fff;
    border-radius: 10px;
    height: auto;
    padding: 20px 14px;
    box-sizing: border-box;
    overflow: hidden;
    display: block;
    .top-item-box {
      padding: 0;
      display: flex;
      align-items: center;
      position: relative;
      &:not(:last-child)::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-bottom: 1px solid #d7dbe3;
      }
      .top-item {
        flex: 1;
        display: flex;
        align-items: center;
        height: 90px;
        padding: 0 10px;
        position: relative;
        &:not(:last-child)::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          // border-bottom: 1px solid #d7dbe3;
          border-right: 1px solid #d7dbe3;
        }
        .item-img {
          display: block;
          width: 27px;
          height: 27px;
          margin-right: 10px;
        }
        .item-right {
          .item-text {
            color: #999;
            font-size: 12px;
            margin-bottom: 4px;
            img {
              display: block;
              width: 6px;
              height: auto;
              margin-left: 10px;
            }
          }
          .item-num {
            color: #111;
            font-size: 16px;
            font-weight: bold;
          }
          .item-more {
            font-size: 12px;
            color: #0956d0;
            cursor: pointer;
          }
        }
      }
    }
    .top-text {
      margin-top: 26px;
      color: #222222;
      font-size: 16px;
    }
    .num-text {
      margin-top: 30px;
      color: #0956d0;
      font-size: 26px;
      font-weight: bold;
    }
  }
  .table-box:nth-child(1) {
    margin-right: 0 !important;
  }
  .bottom-table {
    margin: 15px 15px;
  }
  /deep/ .el-table {
    border-radius: 15px;
  }
  .el-table__body-wrapper,
  .el-table__footer-wrapper,
  .el-table__header-wrapper {
    border-radius: 15px 15px 0px 0;
  }
  .bottom-table .table-box {
    padding: 0;
  }
  .table-box .el-table thead tr th {
    background: #fff;
  }
  .top-bg {
    background: none;
  }
  .itrm {
    margin: 0 15px;
    .itrm-title {
      width: 50%;
      text-align: center;
      font-size: 15px;
      color: #666666;
      position: relative;
    }
    .active {
      font-size: 16px;
      color: #0956d0;
      font-weight: bold;
    }
    .active::before {
      display: block;
      content: "";
      position: absolute;
      width: 32px;
      height: 2px;
      border-radius: 5px;
      background: #0956d0;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
@media screen and (max-width: 480px) {
  .table-box .table-title {
    padding: 0 15px;
  }
  /deep/ .table-box .el-table thead tr th {
    background: #f7f7f7;
  }
  /deep/ .el-table {
    border-radius: 0;
  }
  .table-box {
    border-radius: 15px;
  }
  .bottom-table .table-box .td1-span {
    font-size: 12px;
  }
}
</style>
